import React from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

import {
  parentVariant,
  childLeftVariant,
  childRightVariant,
} from "motions/home";

import "./Home.scss";

import Images from "assets/images";
import ToTheTop from "functions/ToTheTop";

const Home = function Home() {
  return (
    <main className="home">
      <ToTheTop />
      <motion.div
        variants={parentVariant}
        initial="initial"
        animate="animate"
        exit="exit"
        className="placement-full-center"
      >
        <div className="left">
          <div className="profil-img">
            <img src={Images.profil} alt="profil" />
          </div>
          <motion.div variants={childLeftVariant} className="mini-description">
            <p>
              Hey, Je me nomme <span>Kévin</span>
            </p>
            <p>Heureux de vous rencontrer !</p>
          </motion.div>
        </div>
        <div className="right">
          <motion.div variants={childRightVariant} className="title">
            Je suis à la recherche d&apos;un travail dans le dévelopement web.
            <br />
            <span>Front-End / Back-End / Full Stack</span>
          </motion.div>
          <motion.div variants={childRightVariant} className="date">
            <p>
              En <span>CDI</span>, <span>CDD</span>, etc...
            </p>
            <p>
              Que ce soit du <span>Remote</span> ou <span>Présentiel</span>
            </p>
          </motion.div>
          <motion.div variants={childRightVariant} className="formation">
            <div className="school">
              J&apos;ai terminé ma formation à la <span>Wild Code School</span>
            </div>
            <div className="time">
              Depuis le <span>1er Août 2022</span>
            </div>
          </motion.div>
          <Link to="/contactme">
            <motion.div variants={childRightVariant} className="btn-contactme">
              Contactez-moi pour plus d&apos;information
            </motion.div>
          </Link>
        </div>
      </motion.div>
    </main>
  );
};

export default Home;
