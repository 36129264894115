export const title = {
  initial: {
    y: "-10vh",
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 15,
      duration: 0.5,
      delay: 0.3,
    },
  },
};

export const parent = {
  animate: {
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 15,
      duration: 1,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const child = {
  initial: {
    opacity: 0,
    y: "5vh",
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 15,
      duration: 0.2,
    },
  },
};
