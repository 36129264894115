import saov1Image1 from "./saov1_1.jpg";
import saov1Image2 from "./saov1_2.jpg";
import saov1Image3 from "./saov1_3.jpg";
import saov1Thumb1 from "./saov1_1_th.jpg";
import saov1Thumb2 from "./saov1_2_th.jpg";
import saov1Thumb3 from "./saov1_3_th.jpg";
import saov2Image1 from "./saov2_1.jpg";
import saov2Image2 from "./saov2_2.jpg";
import saov2Image3 from "./saov2_3.jpg";
import saov2Thumb1 from "./saov2_1_th.jpg";
import saov2Thumb2 from "./saov2_2_th.jpg";
import saov2Thumb3 from "./saov2_3_th.jpg";
import profil from "./profil.png";

const imgs = {
  saov1Image1,
  saov1Image2,
  saov1Image3,
  saov1Thumb1,
  saov1Thumb2,
  saov1Thumb3,

  saov2Image1,
  saov2Image2,
  saov2Image3,
  saov2Thumb1,
  saov2Thumb2,
  saov2Thumb3,

  profil,

  focusImage1: "https://i.imgur.com/1Tcaw6Y.jpeg",
  focusImage2: "https://i.imgur.com/pK5QiBW.jpeg",
  focusImage3: "https://i.imgur.com/dK3ZJ6s.jpeg",
  focusThumb1: "https://i.imgur.com/1Tcaw6Yt.jpeg",
  focusThumb2: "https://i.imgur.com/pK5QiBWt.jpeg",
  focusThumb3: "https://i.imgur.com/dK3ZJ6st.jpeg",
};

export default imgs;
