import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import ScrollToTop from "components/ScrollToTop";
import { Header, Footer } from "components";
import {
  Home,
  Page404,
  MyProjects,
  Experiences,
  Skills,
  Whoiam,
  ContactMe,
} from "pages";

import "./styles/colors.scss";
import "./App.scss";
import "react-image-lightbox/style.css";

const App = function App() {
  const location = useLocation();
  return (
    <>
      <Header />

      <ScrollToTop />
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/projects" element={<MyProjects />} />
          <Route exact path="/projects/:project" element={<MyProjects />} />
          <Route exact path="/experiences" element={<Experiences />} />
          <Route exact path="/skills" element={<Skills />} />
          <Route exact path="/skills/:skill" element={<Skills />} />
          <Route exact path="/whoiam" element={<Whoiam />} />
          <Route exact path="/contactme" element={<ContactMe />} />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </AnimatePresence>

      <Footer />
    </>
  );
};

export default App;
