import React from "react";
import { Link } from "react-router-dom";

import parse from "html-react-parser";
import moment from "moment";
import { motion } from "framer-motion";

import useCopyText from "hooks/useCopyToClipboard";

import CV from "assets/CV 2021.pdf";

import Images from "assets/images";

import page from "motions/whoiam";
import ToTheTop from "functions/ToTheTop";
import useDescription from "hooks/useDescription";

import ExperiencesList from "./before-experiences-list.json";

import "./Whoiam.scss";

const Whoiam = function Whoiam() {
  useDescription(
    "Retrouvez un petite description à propos de moi, quelques liens utiles et tous mon parcours avant ma reconversion en tant que développeur web."
  );

  return (
    <main className="whoiam">
      <ToTheTop />

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="profil"
      >
        <div className="avatar">
          <img src={Images.profil} alt="Kévin Durand" />
        </div>

        <div className="title">
          <h1>
            Hey, c&apos;est <span>Kévin Durand</span> !
          </h1>
          <h2>Heureux de vous rencontrer !</h2>
        </div>

        <div className="infos">
          <p>
            J&apos;ai {moment().diff(moment("1995-09-10"), "year")} ans et je
            suis en reconversion.
          </p>
          <p>
            J&apos;ai étudié à la <span>Wild Code School</span> pour le Titre de{" "}
            <span>Web Développeur FullStack</span> avec base Javascript,
            c&apos;est à dire, <i className="icon-reactjs" />{" "}
            <span>ReactJS</span> pour la partie Front-End et{" "}
            <i className="icon-express" /> <span>ExpressJS</span> allié à une
            base de données pour la partie Back-End.
          </p>
          <p>
            Pour en savoir plus sur mon parcourt axé développeur web, vous
            pouvez retrouver mes différents projets et expériences en cliquant
            notamment sur{" "}
            <Link to="/projects" title="Projets">
              {"< "}Projets{" />"}
            </Link>{" "}
            ou{" "}
            <Link to="/experiences" title="Expériences">
              {"< "}Expériences{" />"}
            </Link>
            .
          </p>
          <p>
            Vous pouvez aussi retrouvé mon <span>CV</span> au format PDF{" "}
            <a href={CV} target="_blank" rel="noreferrer">
              {"< "}
              ici{" />"}
            </a>
            .
          </p>
        </div>
      </motion.div>

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="hr"
      />

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="important"
      >
        <h3 className="title">Recherche de stage</h3>
        <p>
          Je suis à la recherche d&apos;un travail dans le dévelopement web,
          autant Front-End, Back-End, que Full Stack.
          <br /> En CDI, CDD, ou autres...
        </p>
        <p>
          Que ce soit du remote ou présentiel, cela n&apos;a pas
          d&apos;importance.
        </p>
        <p>Le plus rapidement possible.</p>
        <p>
          Si vous êtes intéressé par mon profil et/ou que vous souhaitez me
          contacter pour plus d&apos;information, n&apos;hésitez pas.
        </p>
      </motion.div>

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="hr"
      />

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="links"
      >
        <h3>Quelques liens qui peuvent vous être utiles</h3>

        <div className="link-list">
          <div className="line">
            <div className="text">
              <a
                href="https://www.linkedin.com/in/k%C3%A9vin-durand-aab7b9223/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-linkedin" /> LinkedIn
              </a>
            </div>
            <button
              className="copy"
              type="button"
              onClick={() =>
                useCopyText(
                  "https://www.linkedin.com/in/k%C3%A9vin-durand-aab7b9223/"
                )
              }
            >
              <i className="icon-copy" />
            </button>
          </div>
          <div className="line">
            <div className="text">
              <a
                href="https://github.com/KevinDurand974"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-github" /> Github
              </a>
            </div>
            <button
              className="copy"
              type="button"
              onClick={() => useCopyText("https://github.com/KevinDurand974")}
            >
              <i className="icon-copy" />
            </button>
          </div>
          <div className="line">
            <div className="text">
              <a
                href="https://stackoverflow.com/users/9439964/kd974"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-stackoverflow" /> Stack Overflow
              </a>
            </div>
            <button
              className="copy"
              type="button"
              onClick={() =>
                useCopyText("https://stackoverflow.com/users/9439964/kd974")
              }
            >
              <i className="icon-copy" />
            </button>
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="hr"
      />

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="activities"
      >
        <h3>Ce que j&apos;aime faire</h3>

        <div className="activity-list">
          <div>- Coder</div>
          <div>- Écouter de la musique</div>
          <div>- Faire de la musique</div>
          <div>- Apprendre des nouvelles technologies</div>
          <div>- Jouer aux jeux vidéos</div>
          <div>- Passer du temps avec mes amis</div>
        </div>
      </motion.div>

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="hr"
      />

      <motion.div
        variants={page}
        initial="initial"
        animate="animate"
        exit="exit"
        className="pro-career"
      >
        <h3>Mon parcours avant ma reconversion</h3>

        <div className="exps">
          {ExperiencesList.map((exp, index) => (
            <div
              className={`experience ${exp.type}`}
              key={exp.dates + exp.title}
            >
              <div className="type">
                {exp.type === "exp" && <i className="icon-work" />}
                {exp.type === "training" && <i className="icon-diploma" />}
              </div>
              <div className="title">{exp.title}</div>
              <div className="dates">{exp.dates}</div>
              <div className="where">{exp.where}</div>
              <div className="description">
                {parse(exp.description.replace(/\n/g, "<br />"))}
              </div>
              {exp.type === "exp" && (
                <details className="gains">
                  <summary>Expériences</summary>
                  {exp.gains.map((gain) => (
                    <ol className="gain" key={gain + Math.random()}>
                      {gain}
                    </ol>
                  ))}
                </details>
              )}
              {exp.type === "training" && (
                <div className="gains">
                  {exp.gains.map((gain) => (
                    <ol className="get" key={gain + Math.random()}>
                      <i className="icon-circle-check" /> {gain}
                    </ol>
                  ))}
                </div>
              )}
              {ExperiencesList.length - 1 !== index && (
                <div className="arrow">
                  <i className="icon-arrow-down" />
                </div>
              )}
            </div>
          ))}
        </div>
      </motion.div>
    </main>
  );
};

export default Whoiam;
