import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Tilt from "react-tilt";

import { motion } from "framer-motion";
import { titleVariant, skillVariant, skillChildVariant } from "motions/skills";
import ToTheTop from "functions/ToTheTop";
import useDescription from "hooks/useDescription";

import SkillsJson from "./skill-list.json";

import "./Skills.scss";

const Skills = function Skills() {
  const params = useParams();

  const [skill, setSkill] = useState(params.skill);
  const [loaded, setLoading] = useState(false);

  useDescription(
    "Vous pouvez retrouver ici la liste de mes skills.\nVous avez le choix entre les soft et hard skills."
  );

  useEffect(() => {
    setLoading(false);
    switch (skill) {
      case "soft":
        setSkill("Soft");
        break;
      case "hard":
        setSkill("Hard");
        break;
      default:
        setSkill("Soft");
        break;
    }
    setLoading(true);
  }, []);

  const reverseSkillType = () => {
    if (skill === "Soft") setSkill("Hard");
    else if (skill === "Hard") setSkill("Soft");
    else {
      setSkill("Soft");
    }
  };
  const inverseSkill = () => {
    if (skill === "Soft") return "Hard";
    if (skill === "Hard") return "Soft";
    return "Soft";
  };

  const shuffleArray = (arr) => {
    const array = arr;
    let currentIndex = arr.length;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  return (
    <main className="skills">
      <ToTheTop />
      {loaded && (
        <>
          <motion.div
            variants={titleVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            className="title"
          >
            <h1>{skill} Skills</h1>

            <Link to={`/skills/${inverseSkill().toLowerCase()}`}>
              <button
                className="switch"
                type="button"
                onClick={reverseSkillType}
              >
                {"<"} {inverseSkill()} Skill {"/>"}
              </button>
            </Link>
          </motion.div>

          <motion.div
            variants={skillVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            className="list"
          >
            {skill === "Soft" && (
              <div className="soft-skills skill-list">
                {shuffleArray(SkillsJson.soft).map((s) => (
                  <motion.div variants={skillChildVariant} key={Math.random()}>
                    <Tilt options={{ max: 30, perspective: 700 }}>
                      <div className="skill">
                        <div className="icon">
                          <i className={`icon-${s.icon}`} />
                        </div>
                        <div className="name">{s.name}</div>
                      </div>
                    </Tilt>
                  </motion.div>
                ))}
              </div>
            )}

            {skill === "Hard" && (
              <div className="hard-skills skill-list">
                {shuffleArray(SkillsJson.hard).map((s) => (
                  <Tilt
                    options={{ max: 30, perspective: 700 }}
                    key={Math.random()}
                  >
                    <motion.div variants={skillChildVariant} className="skill">
                      <div className="icon">
                        <i className={`icon-${s.icon}`} />
                      </div>
                      <div className="name">{s.name}</div>
                    </motion.div>
                  </Tilt>
                ))}
              </div>
            )}
          </motion.div>
        </>
      )}
    </main>
  );
};

export default Skills;
