export const parentNavVarient = {
  animate: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

export const childNavVarient = {
  initial: {
    y: "-20vh",
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      ease: "anticipate",
    },
  },
};

export const pathVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
