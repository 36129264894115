import React from "react";
import { useParams, Link } from "react-router-dom";

import { motion } from "framer-motion";

import Project from "components/Project";
import ToTheTop from "functions/ToTheTop";

import { larrow, rarrow } from "motions/project";

import projects from "./project_list.json";

import "./MyProjects.scss";

const MyProjects = function MyProjects() {
  const { project } = useParams();
  const projectName = project || projects[0].path;
  const projectIncluded = projects.some((name) => name.path === projectName);

  const previousProject = () => {
    const formatedProjectName = projects.map((arrProject) => arrProject.path);
    let currentIndex = formatedProjectName.indexOf(projectName);
    currentIndex -= 1;
    if (currentIndex < 1) currentIndex = formatedProjectName.length - 1;
    return projects[currentIndex].path;
  };
  const nextProject = () => {
    const formatedProjectName = projects.map((arrProject) => arrProject.path);
    let currentIndex = formatedProjectName.indexOf(projectName);
    currentIndex += 1;
    if (currentIndex > formatedProjectName.length - 1) currentIndex = 0;
    return projects[currentIndex].path;
  };

  return (
    <main className="my-projects">
      <ToTheTop />
      <Link to={`/projects/${previousProject()}`}>
        <motion.div
          variants={larrow}
          initial="initial"
          animate="animate"
          exit="exit"
          className="prev btn-project"
        >
          <i className="icon-circle-arrow-left" />
        </motion.div>
      </Link>
      <Link to={`/projects/${nextProject()}`}>
        <motion.div
          variants={rarrow}
          initial="initial"
          animate="animate"
          exit="exit"
          className="next btn-project"
        >
          <i className="icon-circle-arrow-right" />
        </motion.div>
      </Link>

      <div className="btn-mobiles">
        <Link to={`/projects/${previousProject()}`}>Précedent</Link>
        <Link to={`/projects/${nextProject()}`}>Suivant</Link>
      </div>

      {projectIncluded ? (
        projects
          .filter((p) => {
            if (projectName === undefined) {
              if (p.path === "focus") {
                return true;
              }
            } else if (p.path === projectName) {
              return true;
            }

            return false;
          })
          .map((p) => (
            <Project
              title={p.title}
              description={p.description}
              technologies={p.technologies}
              link={p.link}
              screenshots={p.screenshots}
              key={p.title}
            />
          ))
      ) : (
        <section className="project focus">
          <motion.div className="container">
            <h1 className="title">Ce projet n&apos;existe pas !</h1>
            <div className="elements">
              <motion.p className="description">
                Appuyez sur une des flèches pour changer de projet.
              </motion.p>
            </div>
          </motion.div>
        </section>
      )}

      <div className="btn-mobiles">
        <Link to={`/projects/${previousProject()}`}>Précedent</Link>
        <Link to={`/projects/${nextProject()}`}>Suivant</Link>
      </div>
    </main>
  );
};

export default MyProjects;
