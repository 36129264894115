import { useEffect } from "react";

const useDescription = (description) => {
  useEffect(() => {
    const descriptionTag = document.querySelector("meta[name='description']");
    const prevDescription = descriptionTag.getAttribute("content");
    descriptionTag.setAttribute("content", description);

    return () => {
      descriptionTag.setAttribute("content", prevDescription);
    };
  });
};

export default useDescription;
