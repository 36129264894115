const useCopyText = (copied) =>
  new Promise((resolve, reject) => {
    try {
      (async () => {
        await navigator.clipboard.writeText(copied);
        resolve("Copied to Clipboard!");
      })();
    } catch (err) {
      reject(new Error("Error when copied!"));
    }
  });

export default useCopyText;
