export const parentVariant = {
  initial: {
    opacity: 0,
    y: "-20vh",
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      staggerChildren: 0.15,
      staggerDirection: 1,
      duration: 0.8,
      bounce: 0.4,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: "20vh",
    transition: {
      type: "spring",
      duration: 1,
      damping: 10,
      when: false,
    },
  },
};

export const childLeftVariant = {
  initial: {
    y: "10vh",
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      stiffness: 50,
    },
  },
};
export const childRightVariant = {
  initial: {
    opacity: 0,
    x: "10vw",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      damping: 15,
      velocity: 10,
      bounce: 0.4,
      duration: 0.5,
    },
  },
};
