import React from "react";

import { motion } from "framer-motion";
import { parent, child, title } from "motions/experience";
import ToTheTop from "functions/ToTheTop";
import useDescription from "hooks/useDescription";

import "./Experiences.scss";

const Experiences = function Experiences() {
  useDescription(
    "Retrouvez mes plus grosse expériences en tant que développeur sur des cas concret en équipe sur une période donnée."
  );

  return (
    <main className="experiences">
      <ToTheTop />
      <motion.h1 variants={title} initial="initial" animate="animate">
        Mes expériences
      </motion.h1>

      <motion.div
        variants={parent}
        initial="initial"
        animate="animate"
        exit="exit"
        className="list"
      >
        <motion.div variants={child} className="experience">
          <h2 className="title">Hackaton 2</h2>
          <div className="info">
            <div className="by">Wild Code School X ManoMano</div> -
            <div className="date">19 au 21 janvier 2022</div>
          </div>
          <div className="description">
            Nous devions trouver une nouvelle technologie utile au site pour les
            utilisateurs suivant des contraintes données. Tout en restant dans
            l&apos;esprit du site.
          </div>
        </motion.div>

        <motion.div variants={child} className="experience">
          <h2 className="title">Projet 3</h2>
          <div className="info">
            <div className="by">Wild Code School</div> -
            <div className="date">À partir du 7 décembre 2021</div>
          </div>
          <div className="description">
            Projet client, nous devons gérer les rendez-vous, le planning, la
            structure. Le client a besoin d&apos;une interface graphique pour
            créer des coupons/bons qu&apos;il peut distribuer/vendre. Créer un
            PDF, un fichier CSV et envoyer un email automatiquement après
            création. Nous devons créer des codes cadeaux uniques, avec périodes
            de validités. Nous utilisons ReactJS en front et Express, Prisma en
            back pour la gestion des données. 4 personnes par projet.
          </div>
        </motion.div>

        <motion.div variants={child} className="experience">
          <h2 className="title">Hackaton 1</h2>
          <div className="info">
            <div className="by">Wild Code School</div> -
            <div className="date">25 - 26 novembre 2021</div>
          </div>
          <div className="description">
            36h de travail, pour créer un site internet avec les technologies
            que l&apos;on veut sur le thème de la musique. On a utilisé ReactJS
            pour faire le site. 4 personnes par projet.
          </div>
        </motion.div>

        <motion.div variants={child} className="experience">
          <h2 className="title">Projet 2</h2>
          <div className="info">
            <div className="by">Wild Code School</div> -
            <div className="date">18 octobre au 19 novembre 2021</div>
          </div>
          <div className="description">
            2ieme projet utilisateur de la formation, les contraintes données
            sont de créer un site internet avec ReactJS, et d’avoir une
            connection à une REST API. J’ai intégré Firebase en plus pour gérer
            un système d’authentification et de stockage de données. 4 personnes
            par projet
          </div>
        </motion.div>

        <motion.div variants={child} className="experience">
          <h2 className="title">Projet 1</h2>
          <div className="info">
            <div className="by">Wild Code School</div> -
            <div className="date">27 septembre au 8 octobre 2021</div>
          </div>
          <div className="description">
            1er projet utilisateur de la formation, les contraintes données sont
            de créer un site internet uniquement avec de l’HTML et du CSS et
            doit être responsive. Les librairies ne sont pas autorisées. 4
            personnes par projet.
          </div>
        </motion.div>
      </motion.div>
    </main>
  );
};

export default Experiences;
