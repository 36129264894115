import React, { useEffect, useState } from "react";

import "./ToTheTop.scss";

const ToTheTop = function ToTheTop() {
  const [offset, setOffset] = useState(window.scrollY);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [bodyHeight, setBodyHeight] = useState(0);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const scrollEvent = document.addEventListener("scroll", () => {
      setOffset(window.scrollY);
      setViewportHeight(window.innerHeight);
      setBodyHeight(document.querySelector("main").clientHeight);
    });

    return () => {
      document.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  useEffect(() => {
    const resiveEvent = document.addEventListener("resize", () => {
      setViewportHeight(window.innerHeight);
      setBodyHeight(document.querySelector("main").clientHeight);
    });

    return () => {
      document.removeEventListener("resize", resiveEvent);
    };
  }, []);

  return (
    <button
      type="button"
      className={`to-the-top ${
        offset > 0 && bodyHeight > viewportHeight ? "" : "hidden"
      }`}
      onClick={() => handleClick()}
    >
      <i className="icon-upload" />
    </button>
  );
};

export default ToTheTop;
