import React, { useState } from "react";

import PropTypes from "prop-types";

const LazyImage = function LazyImage({
  render,
  thumb,
  error,
  alt,
  className,
  onLoad,
}) {
  const [renderLoaded, setRenderLoaded] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const removeThumb = () => {
    if (renderLoaded || notFound) {
      return false;
    }
    return true;
  };
  const onRenderLoad = () => {
    setRenderLoaded(true);
    if (onLoad) onLoad(true);
  };
  const onRenderError = () => {
    setNotFound(true);
    if (onLoad) onLoad(false);
  };

  return (
    <>
      {!renderLoaded && (
        <img
          src={thumb}
          alt={alt}
          style={removeThumb ? { display: "none" } : {}}
          className={className}
        />
      )}
      <img
        src={render}
        alt={alt}
        onLoad={() => onRenderLoad()}
        onError={() => onRenderError()}
        style={removeThumb ? {} : { display: "none" }}
        className={className}
      />
      {error && notFound && (
        <img src={error} alt="Error" className={className} />
      )}
    </>
  );
};

LazyImage.defaultProps = {
  error: null,
  className: "",
  onLoad: null,
};

LazyImage.propTypes = {
  render: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
  error: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  onLoad: PropTypes.func,
};

export default LazyImage;
