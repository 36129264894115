import React from "react";

import { motion } from "framer-motion";

import "./Footer.scss";

const Footer = function Footer() {
  return (
    <motion.footer
      initial={{ y: "10vh" }}
      animate={{
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.4,
          duration: 0.7,
          delay: 0.3,
        },
      }}
      className="footer"
    >
      <div className="left">
        <p>v1.1</p>
      </div>
      <div className="right">
        <p>Kévin Durand - All right reserved 2022</p>
        <p>Powered by ReactJS and Sass</p>
      </div>
    </motion.footer>
  );
};

export default Footer;
