import React, { useState } from "react";
import axios from "axios";

import { motion } from "framer-motion";
import ToTheTop from "functions/ToTheTop";
import useDescription from "hooks/useDescription";

import { parent, child } from "motions/contactme";

import useCopyText from "hooks/useCopyToClipboard";

import "./ContactMe.scss";

const ContactMe = function ContactMe() {
  useDescription(
    "Contactez-moi grâce au formulaire de contact, vous y retrouverez aussi 4 liens, un pour récupérer mon email, un pour LinkedIn, un pour Github et le dernier pour Stack Overflow"
  );

  const [sendMessage, setSendMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [copyEmail, setCopyEmail] = useState(
    <span>
      <i className="icon-share" /> Cliquez pour copier mon email
    </span>
  );
  const [copyLinkedIn, setCopyLinkedIn] = useState(
    <span>
      <i className="icon-linkedin" /> LinkedIn
    </span>
  );
  const [copyGithub, setCopyGithub] = useState(
    <span>
      <i className="icon-github" /> Github
    </span>
  );
  const [copyStackoverflow, setCopyStackoverflow] = useState(
    <span>
      <i className="icon-stackoverflow" /> Stack Overflow
    </span>
  );

  const [timeoutEmail, setTimeoutEmail] = useState();
  const [timeoutLinkedIn, setTimeoutLinkedIn] = useState();
  const [timeoutGithub, setTimeoutGithub] = useState();
  const [timeoutStackoverflow, setTimeoutStackoverflow] = useState();

  const handleCopyEmail = () => {
    if (timeoutEmail) {
      clearTimeout(timeoutEmail);
    }
    setCopyEmail(
      <span>
        <i className="icon-link" />
        Email copié!
      </span>
    );
    useCopyText("k.durand.974@gmail.com");
    setTimeoutEmail(
      setTimeout(() => {
        setCopyEmail(
          <span>
            <i className="icon-share" /> Cliquez pour copier mon email
          </span>
        );
      }, 3000)
    );
  };
  const handleCopyLinkedin = () => {
    if (timeoutLinkedIn) clearTimeout(timeoutLinkedIn);
    setCopyLinkedIn(
      <span>
        <i className="icon-link" />
        Lien LinkedIn copié!
      </span>
    );
    useCopyText("https://www.linkedin.com/in/k%C3%A9vin-durand-aab7b9223/");
    setTimeoutLinkedIn(
      setTimeout(() => {
        setCopyLinkedIn(
          <span>
            <i className="icon-linkedin" /> LinkedIn
          </span>
        );
      }, 3000)
    );
  };
  const handleCopyGithub = () => {
    if (timeoutGithub) clearTimeout(timeoutGithub);
    setCopyGithub(
      <span>
        <i className="icon-link" />
        Lien Github copié!
      </span>
    );
    useCopyText("https://github.com/KevinDurand974");
    setTimeoutGithub(
      setTimeout(() => {
        setCopyGithub(
          <span>
            <i className="icon-github" /> Github
          </span>
        );
      }, 3000)
    );
  };
  const handleCopyStackoverflow = () => {
    if (timeoutStackoverflow) clearTimeout(timeoutStackoverflow);
    setCopyStackoverflow(
      <span>
        <i className="icon-link" />
        Lien Stack Overflow copié!
      </span>
    );
    useCopyText("https://stackoverflow.com/users/9439964/kd974");
    setTimeoutStackoverflow(
      setTimeout(() => {
        setCopyStackoverflow(
          <span>
            <i className="icon-stackoverflow" /> Stack Overflow
          </span>
        );
      }, 3000)
    );
  };

  const sendedToMe = () => {
    setSendMessage(true);
    setTimeout(() => {
      setName("");
      setSubject("");
      setEmail("");
      setMessage("");
      setSendMessage(false);
    }, 2500);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const body = {
      subject,
      html: `<p><b>From:</b> ${email}</p>
<p>${message}</p>`,
    };

    await axios.post("https://kevin-durand.me/mailer", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    sendedToMe();
  };

  return (
    <main className="contactme">
      <ToTheTop />
      <h1>Contactez-moi</h1>

      <motion.div
        className="content"
        variants={parent}
        animate="animate"
        initial="initial"
        exit="exit"
      >
        <form onSubmit={(e) => handleFormSubmit(e)}>
          <motion.label htmlFor="formName" className="name" variants={child}>
            <h3>Nom</h3>
            <div className="input">
              <input
                type="text"
                name="name"
                id="formName"
                placeholder="The Rock"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span />
            </div>
          </motion.label>

          <motion.label htmlFor="formEmail" className="email" variants={child}>
            <h3>
              Email <span className="required">*</span>
            </h3>
            <div className="input">
              <input
                type="email"
                name="email"
                id="formEmail"
                required
                placeholder="therock@therealrock.io"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span />
            </div>
          </motion.label>

          <motion.label
            htmlFor="formSubject"
            className="subject"
            variants={child}
          >
            <h3>
              Objet <span className="required">*</span>
            </h3>
            <div className="input">
              <input
                type="subject"
                name="subject"
                id="formSubject"
                placeholder="Objet..."
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <span />
            </div>
          </motion.label>

          <motion.label htmlFor="formText" className="message" variants={child}>
            <h3>
              Message <span className="required">*</span>
            </h3>
            <div className="input">
              <textarea
                name="text"
                id="formText"
                cols="30"
                rows="10"
                required
                placeholder="Votre message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <span />
            </div>
          </motion.label>

          <motion.p className="ps" variants={child}>
            <span>*</span> champs requis.
          </motion.p>

          <motion.button type="submit" variants={child}>
            Envoyer
          </motion.button>

          <div className={`message-send ${sendMessage ? "" : "hidden"}`}>
            <h1>Votre message a bien été envoyé !</h1>
          </div>
        </form>

        <div className="hr-vertical" />

        <motion.div className="copyEmail" variants={child}>
          <button className="copy" type="button" onClick={handleCopyEmail}>
            <div className="text">{copyEmail}</div>
            <div className="btn">
              <i className="icon-copy" />
            </div>
          </button>
        </motion.div>
      </motion.div>

      <div className="hr" />

      <motion.div
        className="other-links"
        variants={parent}
        animate="animate"
        initial="initial"
        exit="exit"
      >
        <h1>Retrouvez-moi sur :</h1>

        <motion.button
          className="copy"
          type="button"
          onClick={handleCopyLinkedin}
          variants={child}
        >
          <div className="text">{copyLinkedIn}</div>
          <div className="btn">
            <i className="icon-copy" />
          </div>
        </motion.button>

        <motion.button
          className="copy"
          type="button"
          onClick={handleCopyGithub}
          variants={child}
        >
          <div className="text">{copyGithub}</div>
          <div className="btn">
            <i className="icon-copy" />
          </div>
        </motion.button>

        <motion.button
          className="copy"
          type="button"
          onClick={handleCopyStackoverflow}
          variants={child}
        >
          <div className="text">{copyStackoverflow}</div>
          <div className="btn">
            <i className="icon-copy" />
          </div>
        </motion.button>
      </motion.div>
    </main>
  );
};

export default ContactMe;
