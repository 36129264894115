import React, { useState } from "react";
import PropTypes from "prop-types";

import { motion } from "framer-motion";
import parse from "html-react-parser";

import LazyImage from "plugins/lazyimage";
import Lightbox from "react-image-lightbox";

import "./Project.scss";

import Images from "assets/images";

import {
  container,
  text,
  link as linkVariant,
  screenshot as screenVariant,
  screenshotImg,
} from "motions/project";
import useTitle from "hooks/useTitle";
import useDescription from "hooks/useDescription";

const Project = function Project({
  title,
  description,
  technologies,
  link,
  screenshots,
}) {
  useTitle(`Projet: ${title} | Kévin Durand`);

  useDescription(
    description.replace(/(<br\s?\/>)/gi, " ").replace(/(<([^>]+)>)/gi, "")
  );

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(1);

  const handleLightboxIndexOnClick = (imgIndex) => {
    setPhotoIndex(imgIndex);
    setIsOpen(true);
  };

  const screenImgsSrc =
    screenshots.length > 0
      ? screenshots.map((screenshot) => Images[screenshot.src])
      : [];

  return (
    <section className="project focus">
      <motion.div
        className="container"
        variants={container}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <h1 className="title">❝ {title} ❞</h1>
        <div className="elements">
          <motion.p variants={text} className="description">
            {parse(description)}
          </motion.p>
          <motion.div variants={text} className="technologies">
            {technologies.map((techno) => (
              <div className="technologie" key={techno.name}>
                <div className="icon">
                  <i className={`icon-${techno.icon}`} />
                </div>
                <div className="name">{techno.name}</div>
              </div>
            ))}
          </motion.div>
          <motion.div
            variants={linkVariant}
            initial="initial"
            animate="animate"
            className={`project-link project-${link.type}${
              screenshots.length === 0 ? " full-width" : ""
            }`}
          >
            {link.url ? (
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.title}
              </a>
            ) : (
              <span>{link.title}</span>
            )}
          </motion.div>
          {screenshots.length > 0 && (
            <motion.div variants={screenVariant} className="screenshots">
              {screenshots.map((screen, index) => (
                <motion.div
                  variants={screenshotImg}
                  className="screenshot"
                  key={screen.alt}
                  onClick={() => handleLightboxIndexOnClick(index)}
                >
                  <LazyImage
                    render={Images[screen.src]}
                    thumb={Images[screen.thumb]}
                    alt={screen.alt}
                  />
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </motion.div>
      {isOpen && (
        <Lightbox
          mainSrc={screenImgsSrc[photoIndex]}
          nextSrc={screenImgsSrc[(photoIndex + 1) % screenImgsSrc.length]}
          prevSrc={
            screenImgsSrc[
              (photoIndex + screenImgsSrc.length - 1) % screenImgsSrc.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + screenImgsSrc.length - 1) % screenImgsSrc.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % screenImgsSrc.length)
          }
        />
      )}
    </section>
  );
};

Project.defaultProps = {
  screenshots: [],
};

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  technologies: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  link: PropTypes.objectOf(PropTypes.string).isRequired,
  screenshots: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default Project;
