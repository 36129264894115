export const container = {
  initial: {
    opacity: 0,
    y: "-50vh",
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 0.4,
    },
  },
  exit: {
    opacity: 0,
    y: "50vh",
    transition: {
      type: "tween",
      duration: 0.4,
    },
  },
};

export const text = {
  initial: {
    scaleY: 0.5,
    opacity: 0,
  },
  animate: {
    scaleY: 1,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.4,
      delay: 0.2,
    },
  },
};

export const link = {
  initial: {
    scaleY: 0.5,
    opacity: 0,
    y: "5vh",
  },
  animate: {
    scaleY: 1,
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 0.4,
      delay: 0.3,
    },
  },
};

export const screenshot = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
      delay: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

export const screenshotImg = {
  initial: {
    x: "5vw",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
};

export const rarrow = {
  initial: {
    x: "25vw",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
  exit: {
    x: "25vw",
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
};

export const larrow = {
  initial: {
    x: "-25vw",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
  exit: {
    x: "-25vw",
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
};
