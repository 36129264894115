import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import useTitle from "hooks/useTitle";

import { parentNavVarient, childNavVarient } from "motions/header";

import { motion } from "framer-motion";

import "./Header.scss";

const Header = function Header() {
  const location = useLocation();

  const path = location.pathname;

  const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const realpath = path.split("/");
  realpath.shift();

  useTitle(
    realpath.join("") === ""
      ? "Kévin Durand | Recherche de stage"
      : `${realpath
          .map((p) => ucFirst(p))
          .reverse()
          .join(" - ")} | Kévin Durand`
  );

  const [showMenuMobile, setShowMenuMobile] = useState(false);

  return (
    <>
      <header className="header">
        <div className="who">
          <Link to="/">
            <motion.h1
              initial={{
                x: "20vh",
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.3,
                },
              }}
              className="firstname"
            >
              Kévin
            </motion.h1>
            <motion.h1
              initial={{
                x: "-20vh",
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 1,
                },
              }}
              className="lastname"
            >
              Durand
            </motion.h1>
          </Link>
        </div>

        <motion.nav
          variants={parentNavVarient}
          initial="initial"
          animate="animate"
          className="navigation"
        >
          <NavLink to="/projects">
            <motion.div variants={childNavVarient} className="link">
              Mes Projets
            </motion.div>
          </NavLink>
          <NavLink to="/experiences">
            <motion.div variants={childNavVarient} className="link">
              Expériences
            </motion.div>
          </NavLink>
          <NavLink to="/skills">
            <motion.div variants={childNavVarient} className="link">
              Skills
            </motion.div>
          </NavLink>
          <NavLink to="/whoiam">
            <motion.div variants={childNavVarient} className="link">
              Qui suis-je ?
            </motion.div>
          </NavLink>
          <NavLink to="/contactme">
            <motion.div variants={childNavVarient} className="link">
              Contactez-moi
            </motion.div>
          </NavLink>
        </motion.nav>

        <button
          className="burger-mobile"
          type="button"
          onClick={() => setShowMenuMobile(true)}
        >
          <i className="icon-menu-v4" />
        </button>
      </header>

      <aside className={`nav-mobile ${showMenuMobile ? "show" : ""}`}>
        <button
          className="close-btn"
          type="button"
          onClick={() => setShowMenuMobile(false)}
        >
          <i className="icon-window-close" />
        </button>
        <h4>Menu</h4>
        <nav className="link-mobile">
          <NavLink
            to="/projects"
            className="link"
            onClick={() => setShowMenuMobile(false)}
          >
            Mes Projets
          </NavLink>
          <NavLink
            to="/experiences"
            className="link"
            onClick={() => setShowMenuMobile(false)}
          >
            Expériences
          </NavLink>
          <NavLink
            to="/skills"
            className="link"
            onClick={() => setShowMenuMobile(false)}
          >
            Skills
          </NavLink>
          <NavLink
            to="/whoiam"
            className="link"
            onClick={() => setShowMenuMobile(false)}
          >
            Qui suis-je ?
          </NavLink>
          <NavLink
            to="/contactme"
            className="link"
            onClick={() => setShowMenuMobile(false)}
          >
            Contactez-moi
          </NavLink>
        </nav>
      </aside>
    </>
  );
};

export default Header;
