export const titleVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
      delay: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "tween",
      delay: 0,
    },
  },
};

export const skillVariant = {
  animate: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.06,
    },
  },
  exit: {
    scale: 0,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.4,
      delay: 0,
    },
  },
};

export const skillChildVariant = {
  initial: {
    scale: 0,
    y: "10vh",
  },
  animate: {
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 15,
      duration: 1.5,
    },
  },
  exit: {
    opacity: 0,
  },
};
