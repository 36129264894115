import React from "react";

import "./Page404.scss";

const Page404 = function Page404() {
  return (
    <main className="page404">
      <h1>404 Not Found</h1>
    </main>
  );
};

export default Page404;
